import { graphql, PageProps } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import { Footnote } from '../components/Footnote';
import { Page } from '../components/Page';
import { Section } from '../components/section';
import { StaticTemplateQuery } from '../generated/graphqlTypes';
import { fixFluid } from '../helpers/image';
export type Props = Pick<PageProps<StaticTemplateQuery>, 'data'>;

const Static: React.FC<Props> = ({ data }) => {
  const page = data?.datoCmsStatic;
  if (!page) throw new Error('Invalid static slug');

  return (
    <Page>
      <HelmetDatoCms seo={page?.seoMetaTags} />

      {page?.sectionContent?.map((section) => {
        if (!section) return null;
        return (
          <Section
            key={section.header}
            header={section.header ?? ''}
            backgroundColour={section.backgroundColour?.rgb ?? ''}
            image={fixFluid(section.image?.fluid)}
            imageOnRight={section.imageOnRight ?? false}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: section?.bodyNode?.childMarkdownRemark?.html ?? '',
              }}
            />
            <Footnote
              dangerouslySetInnerHTML={{
                __html: section?.footnoteNode?.childMarkdownRemark?.html ?? '',
              }}
            />
          </Section>
        );
      })}
    </Page>
  );
};

export default Static;

export const query = graphql`
  query StaticTemplate($slug: String!) {
    datoCmsStatic(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      id
      sectionContent {
        header
        backgroundColour {
          rgb
        }
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
        imageOnRight
        image {
          fluid(maxWidth: 910, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsSizes
          }
        }
        footnoteNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
